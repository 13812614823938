import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getAllByClientIdQuery,
  getByIdAndClientIdQuery,
  pkSubEndpointMutation,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { OwnerNews, Owner, GalleryPayload } from '@types'

const ownersApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    createOwner: builder.mutation<Owner, { data: Partial<Owner> }>({
      query: createOneMutation('owner/propietarios'),
      invalidatesTags: ['User', 'Owner', 'Owner', 'OwnerTable'],
    }),
    deleteOwner: builder.mutation<void, { id: number }>({
      query: deleteByIdMutation('owner/propietarios'),
      invalidatesTags: ['User', 'Owner', 'Owner', 'OwnerTable', 'PropertyMixTable'],
    }),
    updateOwner: builder.mutation<Owner, { id: number; data: Partial<Owner> }>({
      query: updateByIdMutation('owner/propietarios'),
      invalidatesTags: ['User', 'Owner', 'Owner', 'Investor', 'OwnerTable', 'PropertyMixTable'],
    }),
    getOwnerById: builder.query<Owner, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery('owner/propietarios'),
      providesTags: (_result, _error, { id }) => [{ type: 'Owner', id }],
    }),
    getOwnerNews: builder.query<OwnerNews[], { clientId: number }>({
      query: getAllByClientIdQuery('owner/novedades'),
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'OwnerNews' as const, id })), 'News'] : ['News'],
    }),
    getOwnerNewsById: builder.query<OwnerNews, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery('owner/novedades'),
      providesTags: (_result, _error, { id }) => [{ type: 'OwnerNews', id }],
    }),
    createOwnerNews: builder.mutation<OwnerNews, { data: Partial<OwnerNews> }>({
      query: createOneMutation('owner/novedades'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable', 'OwnerNews'],
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: createdNew } = await queryFulfilled
          dispatch(
            ownersApi.util.updateQueryData('getOwnerNews', { clientId: createdNew.client }, (draftNews) => {
              draftNews.unshift(createdNew)
              return draftNews
            }),
          )
        } catch {}
      },
    }),
    updateOwnerNews: builder.mutation<OwnerNews, { id: number; data: Partial<OwnerNews> }>({
      query: updateByIdMutation('owner/novedades'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable', 'OwnerNews'],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedNew } = await queryFulfilled
          dispatch(
            ownersApi.util.updateQueryData('getOwnerNewsById', { id, clientId: updatedNew.client }, (draft) => {
              Object.assign(draft, updatedNew)
            }),
          )
          dispatch(
            ownersApi.util.updateQueryData('getOwnerNews', { clientId: updatedNew.client }, (draftNews) => {
              return draftNews.map((draftNew) => (draftNew.id !== id ? draftNew : updatedNew))
            }),
          )
        } catch {}
      },
    }),
    deleteOwnerNews: builder.mutation<void, { id: number; clientId: number }>({
      query: deleteByIdMutation('owner/novedades'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable'],
      async onQueryStarted({ id, clientId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            ownersApi.util.updateQueryData('getOwnerNews', { clientId }, (draftNews) => {
              return draftNews.filter((draftNew) => draftNew.id !== id)
            }),
          )
        } catch {}
      },
    }),
    saveTokkoCache: builder.mutation<void, { client: number, regenerate?: boolean }>({
      query: ({ client, regenerate = false }: { client: number, regenerate?: boolean }) => ({
        url: 'owner/tokko-propiedades/guardar_cache/',
        method: 'POST',
        body: {
          client,
          regenerate
        },
      }),
    }),
    getTokkoCacheInfo: builder.query<{ last_modified: string }, { client: number }>({
      query: ({ client }: { client: number }) => ({
        url: 'owner/tokko-propiedades/ultima_modificacion/',
        params: {
          client,
        },
      }),
    }),
    uploadOwnerNewsFiles: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation('owner/novedades', 'galeria_archivos'),
      invalidatesTags: ['CommunicationTable', 'UserCommunicationTable', 'OwnerNews'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateOwnerMutation,
  useDeleteOwnerMutation,
  usePrefetch: usePrefetchOwners,
  useGetOwnerNewsQuery,
  useCreateOwnerNewsMutation,
  useGetOwnerNewsByIdQuery,
  useUpdateOwnerNewsMutation,
  useDeleteOwnerNewsMutation,
  useSaveTokkoCacheMutation,
  useGetTokkoCacheInfoQuery,
  usePrefetch: usePrefetchOwnerEndpoint, // TODO: split endpoint file
  useGetOwnerByIdQuery,
  useUpdateOwnerMutation,
  useUploadOwnerNewsFilesMutation,
} = ownersApi
